import { Row } from 'react-bootstrap';
import './App.css';
import ScrollResource from './ScrollResource.jsx'
import findmyrecipe from "./images/find-my-recipe.png";
import farmclimate from "./images/farmclimate.png";
import nutrinet from "./images/nutrinet.png";
import swg from "./images/starwars.gif";
import conway from "./images/conwaygif.gif";
import mountains from "./images/mountain.gif";
import sorcery from "./images/sorcery.png";
import musicgraph from "./images/musicgraph.png";
import ecs from "./images/ecs.png";


function Portfolio() {
    const resources = [
        {
            title:"Amazon ECS Agent",
            description:"Contributions to the Amazon Elastic Container Services Agent.",
            date:"Present",
            external:true,
            thumbnail:ecs,
            link:"https://github.com/aws/amazon-ecs-agent/issues?q=is%3Apr%20author%3Atimj-hh%20",
        },
        {
            title:"Find My Recipe",
            description:"A Chrome extension that jumps straight to the recipe on any cooking page.",
            date:"November 2023",
            external:true,
            thumbnail:findmyrecipe,
            link:"https://chromewebstore.google.com/detail/find-my-recipe/cemclconpngfgijkjblgnedlbpkkkapl",
            customLink: {description: "Code", link: "https://github.com/timjhh/Jump-To-Recipe"},
        },
        {
            title:"New England Climate Change Adaptation Tools",
            description:"Climate adaptation resources for Northern New England farmers. Custom Interactive visualizations, tools and downloadable briefs.",
            date:"June 2021 - May 2022",
            external:true,
            thumbnail:farmclimate,
            link:"https://nefarmclimate.com/",
            customLink: {description: "Code", link: "https://github.com/Niles-Lab/Farmers_Calculator"},
        },
        {
            title:"NutriNet",
            description:"Nutritional Stability analysis using graph theory, choropleths and interactive plots. Hosted on a custom database for optimized data querying.",
            date:"October 2021 - June 2023",
            external:true,
            thumbnail:nutrinet,
            link:"https://nutrinet-hatch.netlify.app/",
            customLink: {description: "Code", link: "https://github.com/timjhh/HATCH-Nutrient-Network"},
        },
        {
            title:"Star Wars Co-Occurrence Graph",
            description:'Co-Occurrence of Star Wars Characters with D3.js from the first 6 movies.',
            date:"December 2022",
            external:false,
            thumbnail:swg,
            customLink: {description: "Medium", link: "https://medium.com/@timjharrold/star-wars-character-co-occurrences-as-a-force-directed-graph-ea33cd3212a4"},
            link: "/sw"
        },
        {
            title:"Conway's Game of Life",
            description:"D3.js implementation of Conway's game of life",
            external:false,
            thumbnail:conway,
            link: "/conway"
        },
        {
            title:"Mountains",
            description:"Simple interactive mountains made in D3.js",
            external:false,
            thumbnail:mountains,
            link: "/mountains"
        },
        {
            title:"Sorcery",
            date: "April 2022",
            description:"A C++ dungeon crawler with custom graphics",
            external:true,
            thumbnail:sorcery,
            customLink: {description: "Code", link: "https://github.com/ConnorMilligan/sorcery"}
        },
        {
            title:"MusicGraph",
            date: "March 2021",
            description:"A D3.js force directed graph of your music listening habits",
            external:true,
            thumbnail:musicgraph,
            customLink: {description: "Code", link: "https://github.com/timjhh/MusicGraph"}
        },
    ]

  return (
    <Row>
    <h1 className='display-3 mb-0'>Portfolio</h1>
    <p>Check out some of the projects I've worked on recently</p>
    {resources.map((r) => (
        <Row>
            <ScrollResource 
                key={r.title}
                title={r.title}
                description={r.description}
                date={r.date}
                external={r.external}
                thumbnail={r.thumbnail}
                link={r.link}
                customLink={r.customLink}
            />
        </Row>
    ))}
    </Row>
  );
}

export default Portfolio;
