import "./../App.css";
import { Container, Row, Col, Image, Form, Button, ProgressBar, Modal, Toast, ToastContainer, } from "react-bootstrap";
import { useState, useEffect, useReducer} from "react";
import { XLg, BoxArrowUpRight } from 'react-bootstrap-icons';
import horse from "./../images/trippy-horse.gif";
import { initializeApp } from "firebase/app";
import winner1 from "./winner1.jpeg";
import winner2 from "./winner2.png";
import { getStorage, ref, listAll, uploadBytesResumable, getMetadata, deleteObject } from "firebase/storage";


const animals = ["🐎", "🦓", "🦙", "🪅", "🫏", "🦒", "🦌", "🐌", "🐁", "🦄"]



function Horse() {

  // Aaron - +8 for strava
  // Aaron - +6 for plants
  // Aaron - +4 for petition
  // Aaron - +5 for poll
  // Aaron - +6 for class
  // Antonio - +6 for plants
  // Antonio - +6 for class
  // Brooks - +6 for class
  // Brooks - +6 for plants
  // Brooks - +8 for strava
  // Jon - +3 for strava
  // Ryan - +3 ceded to Kamala
  // Tim - +6 for plants
  // Tim - +5 for poll
  // Tim - +4 for petition
  var initPoints = [["Aaron", 29], ["Antonio", 12], ["Brooks", 20], ["Jon", 3], ["Kamala", 3], ["Tim", 15]]

  const [activities, setActivities] = useState([
    { prompt: "A piece of glass you have blown yourself.", points: "3", floor: 3 },
    { prompt: "A piece of clay you have worked yourself.", points: "3", floor: 3 },
    { prompt: "A piece of metal you have blacksmithed yourself.", points: "3", floor: 3 },
    { prompt: "A candle you have made yourself.", points: "3", floor: 3 },
    { prompt: "A painting you have created.", points: "3", floor: 3 },
    { prompt: "A physical item you have created yourself not present in the above list. Point value shall be assigned at the time of judging.", points: "3+", floor: 3 },
    { prompt: "Proof that you have signed up for a class. The class must be at least 1 hour in length. +1 point for every two weeks the class runs. e.g. a four week class would be worth 5 points.", points: "3", floor: 3 },
    { prompt: "Partake in some winter sport. This includes snowboarding, skiing, or ice skating.", points: "3", floor: 3 },
    { prompt: "An honest review (1 paragraph of >6 sentences) of an album you found in a record shop.", points: "3", floor: 3 },
    { prompt: "An honest review (1 paragraph of >6 sentences) of a book you have finished. You may be, at most, 60% finished with this book prior to competiton.", points: "3", floor: 3 },
    { prompt: "Posted your honest book review to Goodreads or StoryGraph.", points: "1", floor: 1 },
    { prompt: "Created a Goodreads or StoryGraph to post your honest book review to.", points: "2", floor: 2 },
    { prompt: "A handwritten poll of something you feel strongly about. This must have 5 or more responses. +1 point for every 10 responses.", points: "3-8", floor: 3 },
    { prompt: "A hand signed petition of something you feel strongly about. This must have 5 or more signatures. +1 point for every 10 responses.", points: "3-8", floor: 3 },
    { prompt: "Cold plunge into a body of water.", points: "3", floor: 3 },
    { prompt: "Volunteer somewhere.", points: "3", floor: 3 },
    { prompt: "Attend a symphony or a musical event.", points: "3", floor: 3 },
    { prompt: "Attend a play or live theatrical performance.", points: "3", floor: 3 },
    { prompt: "Do a sensory deprivation tank.", points: "3", floor: 3 },
    { prompt: "Get your nails done.", points: "3", floor: 3 },
    { prompt: "Make a meal for somebody you are not living with or dating.", points: "3", floor: 3 },
    { prompt: "Celebrate a holiday.", points: "3", floor: 3 },
    { prompt: "Proof of an entire puzzle you have completed. You may be, at most, 10% finished with this puzzle prior to competiton.", points: "3", floor: 3 },
    { prompt: "Write your name in a board game box, because you won that board game.", points: "3", floor: 3 },
    { prompt: "Play trivia at a bar.", points: "3", floor: 3 },
    { prompt: "A strava screenshot of a run. Each mile you run will add one point to this challenge. Only one run may be submitted for points. The speed of a run is considered to be 3.75 mph average speed or a 16 minute mile.", points: "0-8", floor: 0 },
    { prompt: "Enjoy some soft serve.", points: "3", floor: 3 },
    { prompt: "Donate some goods to charity. Examples include Goodwill, canned goods, and toy drives.", points: "3", floor: 3 },
    { prompt: "Get higher than the space needle. (The space needle is at ~825')", points: "3", floor: 3 },
    { prompt: "Go to a museum or aquarium.", points: "3", floor: 3 },
    { prompt: "Memorize a poem by submitting a photo or screenshot here and reciting it on the judgement day.", points: "3", floor: 3 },
    { prompt: "Eat a bag of Dicks at 4:20.", points: "3", floor: 3 },
    { prompt: "Enjoyed your bag of Dicks at 4:20AM.", points: "1", floor: 1 },
    { prompt: "Facetime an animal.", points: "3", floor: 3 },
    { prompt: "One baked good, pastry or bagel you have made yourself.", points: "3", floor: 3 },
    { prompt: "A hand crocheted/knitted/embroidered little guy.", points: "3", floor: 3 },
    { prompt: "Learned to crochet/knit/embroider to make your little guy.", points: "2", floor: 2 },
    { prompt: "A piece of origami consisting of 8+ folds. Folds must be decisive and the end product must resemble something.", points: "3", floor: 3 },
    { prompt: "Create a piece of printed media such as a poster, book, or textile.", points: "3", floor: 3 },
    { prompt: "Four sentences hand written in a language you did not speak prior to the competition. You must be able to interpret this text.", points: "3", floor: 3 },
    { prompt: "Acquire a plant. +1 point for every week this plant has lived in your custody before the judgement day.", points: "1", floor: 1 },
    { prompt: "Hang a decoration on your wall.", points: "2", floor: 2 },
    { prompt: "Mail a letter to a prisoner", extra: (<a className="text-decoration-none" rel="noreferrer" target="_blank" href="https://writeaprisoner.com/"><BoxArrowUpRight/></a>), points: "3", floor: 3 },
    { prompt: "Play pinball.", points: "3", floor: 3 },
    { prompt: "Win a game of Dance Dance Revolution.", points: "3", floor: 3 },
    { prompt: "Leave a yelp or google review somewhere.", points: "3", floor: 3 },
    { prompt: "A rock from a beach.", points: "2", floor: 2 },
    { prompt: "A selfie with somebody that has more than 1000 instagram followers.", points: "2", floor: 2 },
    { prompt: "Trap an inch of Seattle rain water.", points: "2", floor: 2 },
    { prompt: "Mail a letter.", points: "2", floor: 2 },
    { prompt: "Find a piece of thrifted witch or wizard apparel.", points: "2", floor: 2 },
    { prompt: "Find a miniature horse (open to interpretation).", points: "2", floor: 2 },
    { prompt: "Find a healing crystal.", points: "2", floor: 2 },
    { prompt: "Buy one item from Archie Mcphee.", points: "2", floor: 2 },
    { prompt: "Take a selfie with a stranger.", points: "2", floor: 2 },
    { prompt: "Find symbology of an animal.", points: "2", floor: 2 },
    { prompt: "Eat a balanced breakfast.", points: "2", floor: 2 },
    { prompt: "Ride a ferry.", points: "2", floor: 2 },
    { prompt: "Ride the monorail.", points: "2", floor: 2 },
    { prompt: "Touch some grass.", points: "2", floor: 2 },
    { prompt: "Do a face mask.", points: "2", floor: 2 },
    { prompt: "Do something European.", points: "2", floor: 2 },
    { prompt: "Break this site.", points: "3", floor: 3 },
    { prompt: "Voted for Hawthorne in his time of need.", extra: (<a className="text-decoration-none" rel="noreferrer" target="_blank" href="https://americasfavpet.com/2025/hawthorne-7049"><BoxArrowUpRight/></a>), points: "3", floor: 3, disabled: true },
  ])

  const [completedChallenges, setCompletedChallenges] = useState([]);
  const [dialog, setDialog] = useState("")
  const [ignored, forceUpdate] = useReducer(x => x + 1, 0);
  const [hasFetched, setHasFetched] = useState(false);
  const [urls, setURLs] = useState([])
  

  const [phone, setPhone] = useState('');

  const [app, setApp] = useState(null);

  const [files, setFiles] = useState([]);
  const [login, setLogin] = useState("")
  const [showLogin, setShowLogin] = useState(false)

  const [isUploading, setIsUploading] = useState(false);
  const [uProgress, setUProgress] = useState(0);
  const [uVariant, setUVariant] = useState("info")
  const [users, setUsers] = useState({})
  const [leaderboardPts, setLeaderboardPts] = useState([])

  // image view modal toggles.
  const [show, setShow] = useState(false);
  const [currentURL, setCurrentURL] = useState("")
  const handleClose = () => setShow(false);
  const handleShow = (u) => {
    setShow(true)
    setCurrentURL(u)
  }

  useEffect(() => {
    const firebaseConfig = {
      storageBucket: process.env.REACT_APP_BUCKET_KEY
    };
    let fbApp = initializeApp(firebaseConfig)
    setApp(fbApp);

    let contestants = process.env.REACT_APP_AUTH_USERS.split('|');
    let contestantDir = {};
    contestants.forEach(n => {
      let usr = n.split(",");
      contestantDir[usr[1]] = usr[0];
    })
    setUsers(contestantDir)
    fetchLeaderboard(fbApp, contestantDir);
  }, [])

  // isValidNumber denotes if a user is valid. Users are stored as `user,number`.
  function isValidNumber(number) {
    if (!number) return false;
    let contestants = process.env.REACT_APP_AUTH_USERS.split('|');
    let isValid = false
    contestants.forEach(n => {
      if (n.split(",")[1] === number) isValid = true;
    })
    return isValid;
  }

  async function uploadImage(file) {
    return new Promise((resolve, reject) => {
    // Create a root reference
    const storage = getStorage(app);

    // Create file metadata including the content type
    /** @type {any} */
    const metadata = {
      customMetadata: {
        question: file.question,
        points: file.points
      }
    };

    if (completedChallenges.includes(file.question)) {
      let q = file.question.length > 80 ? (file.question.substring(0, 80) + "...") : file.question;
      let text = `Are you sure you want to overwrite your submission for "${q}"?`
      if (!window.confirm(text)) {
        setDialog("⚠️ File upload canceled")
        setUVariant("warning") 
        return
      }
    }

    if ((file.size / 1024 / 1024) > 20) {
      setDialog("⚠️ File size must be < 20MiB")
      setUVariant("warning") 
      return
    }
    const storageRef = ref(storage, (phone + '/' + file.name));
    const uploadTask = uploadBytesResumable(storageRef, file, metadata);

    // Listen for state changes, errors, and completion of the upload.
    uploadTask.on('state_changed',
      (snapshot) => {
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded.
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

        switch (snapshot.state) {
          case 'paused':
            console.log('Upload is paused');
            break;
          case 'running':
            let relProgress = progress / ((1 / files.length))
            let totalProgress = (uProgress + relProgress)
            setUProgress(totalProgress)
            setDialog(`Upload ${totalProgress.toFixed(2)}% Complete`)
            setUVariant("info")
            break;
          default:
            console.log('Unknown upload state: ' + snapshot.state)
            break;
        }
      },
      (error) => {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        console.error(`error: ${error.code} ${error.message}`)
        setDialog("Failed to upload submission")
        setUVariant("error")
        setIsUploading(true);
        resolve();
        return
        
      },
      () => {
        setDialog("🎉 Successfully uploaded submission!")
        setUVariant("success")
        setIsUploading(true);
        resolve();
      }
    );
    })
  }

  async function handleRemove(activity) {
    await removeFile(activity)
    await fetchLeaderboard(app, users)
    await updateActivityBoard();
  }

  function removeFile(activity) {
    return new Promise((resolve, reject) => {
      const storage = getStorage(app);
      const fileName = completedChallenges[activity.prompt];
      const deleteRef = ref(storage, fileName);

      let q = activity.prompt.length > 80 ? (activity.prompt.substring(0, 80) + "...") : activity.prompt;
      let text = `Are you sure you want to delete your subimssion for "${q}"?`
      if (!window.confirm(text)) {
        return
      }

      deleteObject(deleteRef).then(() => {
        let newActivities = activities.map(e => {
          if(activity.prompt === e.prompt) {
            e.url = null
          }
          return e
        })
        setActivities(newActivities)
        setDialog("🎉 Successfully deleted submission")
        resolve();
      }).catch((error) => {
        console.error("error deleting file " + error)
        setDialog("Failed to delete file " + error)
        resolve();
      });
    })
  }

  // sleep time expects milliseconds.
  function sleep(time) {
    return new Promise((resolve) => setTimeout(resolve, time));
  }

  // handleChange aggregates uploaded files.
  function handleChange(event) {
    if (event.target.files.length > 0) {
      let fileList = files;
      let file = event.target.files[0]
      file.question = event.target.getAttribute('question')
      file.points = event.target.getAttribute('points')
      fileList.push(event.target.files[0]);
      setFiles(fileList);
    }
  }

  function handleSubmit(event) {
    event.preventDefault();
    setIsUploading(true);
    if (files.length === 0) {
      setDialog("⚠️ No files to submit")
      setIsUploading(false);
      return
    }
    if (phone.length < 10 || !isValidNumber(phone)) {
      setDialog("⚠️ Invalid phone number")
      setIsUploading(false);
      return
    }

    console.log("uploading " + files.length + " files....")
    files.forEach(async function(f) {
      await uploadImage(f);
      await fetchLeaderboard(app, users)
      await updateActivityBoard();
      setIsUploading(false)
      setUProgress(0)
      setFiles([]);
    })

  }

  async function handleFetch(event) {
    if(event !== null) event.preventDefault();
    // We have already logged in once. Reset other metadata.
    if(hasFetched) {
      setCompletedChallenges([]);
      const newActivities = activities.map(e => {
        e.url = null;
        return e
      })
      setActivities(newActivities)
    }
    console.log("Logging in as " + phone)
    if (!isValidNumber(phone)) {
      setDialog("⚠️ Invalid phone number")
      console.log("Login failed.")
      return
    }
    if (login !== users[phone]) {
      console.log("Logged in successfully")
      setShowLogin(true)
      setLogin(users[phone])
    }

    await updateActivityBoard();
    setHasFetched(true)
    forceUpdate();
  }

  function getURLFromPath(path) {
    return "https://firebasestorage.googleapis.com/v0/b/horse-data-33f97.firebasestorage.app/o/"+encodeURIComponent(path)+"?alt=media"
  }

  function updateActivityBoard() {
    let storage = getStorage(app);
    let completedChals = [];

    getUserSubmissions(storage).then(data => {
      data.filter((value, index, self) => {
        return self.findIndex(v => v.customMetadata.question === value.customMetadata.question) === index;
      }).forEach(q => {
        completedChals[q.customMetadata.question] = q.fullPath;
      })
      setCompletedChallenges(completedChals);
      const newActivities = activities.map(e => {
        if(Object.keys(completedChals).includes(e.prompt)) {
          return {...e, url: getURLFromPath(completedChals[e.prompt])}
        } else {
          return e
        }
      })
      setActivities(newActivities)
    }).catch((error) => {
      console.log("error fetching metadata " + error)
    })
  }

  function fetchLeaderboard(fbApp, contestants) {
    let tempLeader = [];
    let links = [];
    let storage = getStorage(fbApp);

    getImageMetadata(storage, Object.keys(contestants)).then(data => {
      data.filter((value, index, self) => {
        return self.findIndex(v => v.key === value.key) === index;
      }).forEach(q => {
        links[q.question] = getURLFromPath(q.path)
        let u = contestants[q.number]
        if(!tempLeader[u]) {
          tempLeader[u] = +(q.points)
        } else {
          tempLeader[u] += +(q.points)
        }
      })
      // Add in initial points for sliding scale challenges.
      initPoints.forEach(p => {
        if(tempLeader[p[0]]) tempLeader[p[0]] += +(p[1])
        else tempLeader[p[0]] = +(p[1])
      })
      const pointTable = [...Object.entries(tempLeader)].sort((a, b) => b[1] - a[1])
      setLeaderboardPts(pointTable)
      setURLs(links)
      forceUpdate();
    })
  }

  async function getUserSubmissions(storage){
    const imageRefs = await listAll(ref(storage, `/${phone}/`))
    const imagePromises = imageRefs.items.map(entry => getMetadata(entry));
    const metadata = await Promise.all(imagePromises);
    return metadata
  }

  async function getImageMetadata(storage, folder) {
    const imageRefs = folder.map(f => listAll(ref(storage, `/${f}/`)))
    const folderRef = await (await Promise.all(imageRefs)).filter(r => r.items.length > 0)
    const imagePromises = folderRef.map(entry => entry.items.map(i => getMetadata(i)));
    const metadata = await Promise.all(imagePromises);
    const oneMetadata = [].concat(...metadata);
    const answers = await Promise.all(oneMetadata.map(m => m.then(i => ({key: (i.fullPath.split("/")[0]+i.customMetadata.question), number: i.fullPath.split("/")[0], question: i.customMetadata.question, points: i.customMetadata.points})).catch(console.error)))
    return answers
  }

  function getAlertStatus(mode) {
    let val = mode === "variant" ? "" : "outline-"
    if(dialog.includes("Welcome") || dialog.includes("Complete")) val += "info"
    else if(dialog.includes("Success")) val += "success"
    else if(dialog.includes("Failed to")) val += "danger"
    else val += "warning"
    return val;
  }

  return (
    <div>
      <ToastContainer position="bottom-end" className="p-3 position-fixed">
        <Toast bg={getAlertStatus("variant")}  onClose={() => {setDialog("")}} show={dialog !== ""} delay={6000} autohide>
          <Toast.Body>{dialog}</Toast.Body>
        </Toast>
        <Toast bg={"info"}  onClose={() => {setShowLogin(false)}} show={showLogin} delay={6000} autohide>
          <Toast.Body>Welcome, {users[phone]} 🐎</Toast.Body>
        </Toast>
      </ToastContainer>
      <Image rounded className="w-25 d-block  mx-auto" src={horse} />
      <Container id="home">
        <Row>
          <Col
            className="text-center mx-auto align-items-center justify-content-center"
            xs={{ span: 12, offset: 0 }}
          >
            <h1 className="display-3">SAD HORSE</h1>
            <hr
              style={{
                height: "1px",
                border: "1px solid white",
                color: "#fff",
              }}
            />
            <p>
              Dark times approach the pacific northwest. More than the sun setting early, a curse has been cast upon the land. The dark wizard Noah Va has cursed the Pacific Northwest with darkness so wild horses 🐎 cannot run free unless a concoction is made to warm the hearts and souls of the sleepy Seattlites.
            </p>
            <p>
              Contestants will have until Febuary 28, 2025 to complete as few or many of the following as possible. The more goals challengers complete, the stronger the potion will be to claim one wild Kamala Horse.
              Around the end date, a meeting will take place to see <a href="https://www.youtube.com/watch?v=R_FQU4KzN7A">whose potion is the strongest</a>.
              <br/><br/>
              <b>All challenges must contain a photo or video submission taken at most one week prior to the competition starting.</b> <b>Duplicate pictures are not allowed.</b>
            </p>
            <hr />
          </Col>
        </Row>
      </Container>
      <Container
        className="mx-auto"
        fluid
      >
        <Col xs={{ span: 12, offset: 0 }} md={{ span: 6, offset: 3 }}>
          <Row>
            <Col className="mb-3" xs={12}>
              <h2 className="display-3">Winner 🏆 </h2>
              <Image rounded className="w-75 d-block mx-auto" src={winner2}/>
            </Col>
            <hr/>
          </Row>
          <Form className="p-4 m-3 rounded border">
            <Row>
            </Row>
            <Row className="d-flex justify-content-center">
              <Form.Group as={Col} xs="6" controlId="validationCustomUsername">
                <Form.Group>
                  <Form.Label className="mx-2">Phone Number</Form.Label>
                  <Form.Control
                    required
                    inputMode="numeric" 
                    onChange={e => setPhone(e.target.value)}
                    type="text"
                    placeholder="1234567890"
                  />
                </Form.Group>
                {(login !== "") && (<Form.Label className="my-2">Logged in as: {login}</Form.Label>)}
              </Form.Group>
              <Col xs={6}>
                <div className="d-flex flex-column justify-content-start">
                  <Button variant="dark" className="m-1" onClick={handleFetch} type="submit">Log In</Button>
                  <Button variant="dark" className="m-1" onClick={handleSubmit} type="submit">Submit</Button>
                </div>
                {isUploading &&
                  (
                    <>
                      <label>Upload progress:</label>
                      <ProgressBar variant={uVariant} now={uProgress} label={`${uProgress}%`} />
                    </>
                  )}
              </Col>
            </Row>
          </Form>
          <hr />
          <Row>
            <Col xs={12}>
              <h2 className="display-3">Leaderboard 🏅 </h2>
              {leaderboardPts.map((e, idx) => (
                <div key={"ldr" + idx}>
                  <h3 className="w-100">{e[0]} {animals[idx]}{idx === 0 && "🎉🎉🎉🎉🎉"}</h3>
                  <ProgressBar animated variant={idx === 0 ? "warning" : "info"} label={`${e[1]}`} now={e[1]} max={180} key={idx} />
                </div>
              ))}
            </Col>
          </Row>
          <hr />
          <Row>
            <Col xs={12}>
              <h2 className="display-3">Activities</h2>
              {activities.map((e, idx) => (
                <Row key={"activity" + idx} className="d-flex mx-2">
                  <Col xs={12} md={6}>
                    <h5 className="text-left">{e.prompt} {e.extra}</h5>
                    <h5 className="text-right"><b>{e.points} points</b>{e.url && "✅"}</h5>
                  </Col>
                  <Col xs={12} md={6}>
                    {e.url ? (
                        <>
                        <Button className="m-1" variant="dark" onClick={() => handleShow(e.url)}>
                          View submission
                        </Button>
                        <Button className="m1" variant="danger" onClick={() => handleRemove(e)}>
                          Remove submission
                        </Button>
                        {e.url === currentURL && (
                        <Modal show={show} onHide={handleClose}>
                          <Image rounded alt={e.prompt} src={currentURL} />
                          <XLg onClick={handleClose} className="position-absolute top-0 end-0 m-2" style={{color: "black", "cursor": "pointer"}} />
                        </Modal>
                        )}
                        </>
                    ) : (
                      <Form.Group controlId="formFile" className="mb-3">
                        <Form.Control disabled={e.disabled} question={e.prompt} points={e.floor} onChange={handleChange} type="file" />
                      </Form.Group>
                    )}
                  </Col>
                  <hr />
                </Row>
              ))}
            </Col>
          </Row>
        </Col>
      </Container>

    </div>
  )
}

export default Horse;